import { RouterLink } from "mobx-state-router";
import { RoutesEnum } from "../../router";
import HomeIcon from "@mui/icons-material/Home";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ArchiveIcon from "@mui/icons-material/Archive";
import QuizIcon from "@mui/icons-material/Quiz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import { Avatar } from "@mui/material";
import "./style.css";
import userStore from "../../store/UserStore";
import { DrawerSettingMenu } from "./DrawerSettingDialog";
import { observer } from "mobx-react-lite";
import React from "react";

export const CustomDrawer = observer(() => {
  const [selectedName, setSelectedName] = useState(window.location.pathname);
  const { me } = userStore;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setIsOpen] = useState(anchorEl);
  const handleClick = (event) => {
    setIsOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="custom-drawer">
      <Toolbar />
      {localStorage.getItem("token") && (
        <List>
          <Divider />
          <ListItem disablePadding>
            <RouterLink routeName={RoutesEnum.HOME} style={{ width: "100%" }}>
              <ListItemButton
                selected={selectedName === "/"}
                onClick={() => setSelectedName("/")}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Заявки"} />
              </ListItemButton>
            </RouterLink>
          </ListItem>
          <ListItem disablePadding>
            <RouterLink
              routeName={RoutesEnum.MY_REQUESTS}
              style={{ width: "100%" }}
            >
              <ListItemButton
                selected={selectedName === "/my-requests"}
                onClick={() => setSelectedName("/my-requests")}
              >
                <ListItemIcon>
                  <AutorenewIcon />
                </ListItemIcon>
                <ListItemText primary={"В работе"} />
              </ListItemButton>
            </RouterLink>
          </ListItem>
          <ListItem disablePadding>
            <RouterLink
              routeName={RoutesEnum.ARCHIVE_MY_REQUESTS}
              style={{ width: "100%" }}
            >
              <ListItemButton
                selected={selectedName === "/archive"}
                onClick={() => setSelectedName("/archive")}
              >
                <ListItemIcon>
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText primary={"Архив"} />
              </ListItemButton>
            </RouterLink>
          </ListItem>

          <ListItem disablePadding>
            <RouterLink routeName={RoutesEnum.FAQ} style={{ width: "100%" }}>
              <ListItemButton
                selected={selectedName === "/faq"}
                onClick={() => setSelectedName("/faq")}
              >
                <ListItemIcon>
                  <QuizIcon />
                </ListItemIcon>
                <ListItemText primary={"FAQ"} />
              </ListItemButton>
            </RouterLink>
          </ListItem>
        </List>
      )}

      <Divider />

      <Avatar
        className="custom-drawer-avatar"
        onClick={handleClick}
        style={{ position: "relative" }}
      >
        {me?.username[0] || "A"}
        <DrawerSettingMenu
          handelClick={handleClick}
          open={open}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
        />
      </Avatar>
    </div>
  );
});
