import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Router } from "./router/Router";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { RouterContext, RouterView } from "mobx-state-router";
import { RoutesEnum, viewMap } from "./router";
import { ErrorModal } from "./components/modal/ErrorModal";
import { LoadingModal } from "./components/modal/LoadingModal";
import { useEffect } from "react";
import userStore from "./store/UserStore";
import { SnackbarProvider } from "notistack";
import { observer } from "mobx-react";
import { ImageModal } from "./components/modal/ImageModal";
import SideBar from "./components/SideBar/SideBar";
import "./App.css";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const routerStore = Router();

  useEffect(() => {
    if (!userStore.isLoggedIn && userStore.isInit) {
      routerStore.goTo(RoutesEnum.LOGIN);
    }
  }, [userStore.isLoggedIn, userStore.isInit]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <RouterContext.Provider value={routerStore}>
        <SnackbarProvider maxSnack={3}>
          <SideBar />
          <div className="main">
            <RouterView viewMap={viewMap} />
          </div>
          <LoadingModal />
          <ErrorModal />
          <ImageModal />
        </SnackbarProvider>
      </RouterContext.Provider>
    </ThemeProvider>
  );
}

export default observer(App);
