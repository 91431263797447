import BaseApi from "./base/BaseApi";

class UserApi extends BaseApi {
  static INTERMEDIARY_URL = "/user";

  static async login(data) {
    const url = this.buildUrl("/auth/token");
    return await this.sendPost({ url, data });
  }

  static async me() {
    const url = this.buildUrl("/me");
    return await this.sendGet({ url, isAuth: true });
  }

  static async getRequests() {
    const url = this.buildUrl("/request");
    return await this.sendGet({ url, isAuth: true });
  }

  static async getMyRequests() {
    const url = this.buildUrl("/request/my");
    return await this.sendGet({ url, isAuth: true });
  }

  static async acceptRequest(requestId) {
    const url = this.buildUrl(`/request/${requestId}/status`);
    return await this.sendPost({
      url,
      data: {
        status: RequestStatusEnum.IN_PROGRESS,
      },
      isAuth: true,
    });
  }

  static async declineRequest(requestId) {
    const url = this.buildUrl(`/request/${requestId}/status`);
    return await this.sendPost({
      url,
      data: {
        status: RequestStatusEnum.DECLINED,
      },
      isAuth: true,
    });
  }
}

export default UserApi;

export const RequestStatusEnum = {
  NEW: "new",
  IN_PROGRESS: "in_progress",
  DECLINED: "declined",
  DONE: "done",
};

export const translateType = {
  org_question: "Организационный вопрос(HR)",
  tech_question: "Технический вопрос",
};

export const translateStatus = {
  [RequestStatusEnum.NEW]: "Новая",
  [RequestStatusEnum.IN_PROGRESS]: "В работе",
  [RequestStatusEnum.DECLINED]: "Отклонена",
  [RequestStatusEnum.DONE]: "Завершена",
};

export const RoleUserEnum = {
  TG_USER: "tg_user",
  ADMIN: "admin",
  USER_HR: "user_hr",
  USER_IT: "user_it",
};

export const USER_ROLES = [
  RoleUserEnum.ADMIN,
  RoleUserEnum.USER_HR,
  RoleUserEnum.USER_IT,
];
