import {
  Button,
  Chip,
  Container,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { formatTgUser } from "../../utils/tgUser";
import { IMAGE_URL } from "../../constants";
import { formatCustomDate } from "../../utils/date";
import appStore from "../../store/AppStore";
import { observer } from "mobx-react";
import {
  RequestStatusEnum,
  translateStatus,
  translateType,
} from "../../api/UserApi";
import { RequestChat } from "../../components/request/RequestChat";
import webSocketStore from "../../store/WebSocketStore";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../constants";
import UserApi from "../../api/UserApi";
import Cookies from "js-cookie";
import { TextField } from "@mui/material";

const chipByStatus = {
  [RequestStatusEnum.NEW]: (
    <Chip label={translateStatus[RequestStatusEnum.NEW]} color="info" />
  ),
  [RequestStatusEnum.IN_PROGRESS]: (
    <Chip
      label={translateStatus[RequestStatusEnum.IN_PROGRESS]}
      color="primary"
    />
  ),
  [RequestStatusEnum.DECLINED]: (
    <Chip label={translateStatus[RequestStatusEnum.DECLINED]} color="error" />
  ),
  [RequestStatusEnum.DONE]: (
    <Chip label={translateStatus[RequestStatusEnum.DONE]} color="success" />
  ),
};

export const ViewChatRequest = observer(
  ({ request, children, detail, setClose, setModalPanel }) => {
    const [panel, setPanel] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [initialText, setInitialText] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);

    const fast_function = (text) => {
      setIsDisabled(true);
      webSocketStore.binarySocketService.sendMessageToUser(text);

      setTimeout(() => {
        setIsDisabled(false);
      }, 3000);
    };

    const handleSave = async () => {
      try {
        const user = await UserApi.me();
        const csrfToken = Cookies.get("csrftoken");
        const headers = {};
        if (csrfToken) {
          headers["X-CSRFToken"] = csrfToken;
        }
        let form_panel = {
          type: user.data.role,
          text: panel,
        };
        await axios.patch(`${BASE_URL}/user/draft`, form_panel, {
          headers: headers,
        });
        setInitialText(panel);
        setIsEditing(false);
      } catch (error) {
        console.error("Error saving the text:", error);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        const headers = {};
        const user = await UserApi.me();
        try {
          let form_panel = {
            type: user.data.role,
          };
          const response = await axios.post(
            `${BASE_URL}/user/draft`,
            form_panel,
            {
              headers: headers,
            }
          );
          setPanel(response.data);
          setInitialText(response.data);
        } catch (error) {
          console.error("Error fetching FAQ data:", error);
        }
      };

      fetchData();
    }, []);
    return (
      <Paper
        key={request.id}
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1.5fr  1fr",
          height: "100%",
          width: "100%",
          margin: "0",
          padding: "2rem",
          borderRadius: "20px",
          backgroundImage:
            "linear-gradient(to left,rgba(0, 36, 197, 0.56),rgba(13, 11, 87, 0.61))",
          border: "1px solid rgba(62, 62, 62, 0.4)",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.38)",
        }}
      >
        <div>Здесь будет список заявок</div>
        <div>
          <Stack>
            <Container
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
              }}
            >
              <Stack p={1} spacing={1}>
                <Stack direction="row" spacing={2}>
                  <Typography variant="h5">Заявка №{request.id}</Typography>
                  {detail && chipByStatus[request.status]}
                  {request.is_trans ? (
                    <Chip label={"Переданная заявка"} color="error" />
                  ) : (
                    <></>
                  )}
                  {request.is_view ? (
                    <></>
                  ) : (
                    <Chip label={"!!!Новое сообщение!!!"} color="success" />
                  )}
                </Stack>
                {formatTgUser(request.user)}
                <Typography>
                  Тема: {translateType[request.type] || "Новая заявка"}
                </Typography>
                <Typography>
                  Описание: {request.text || "без описания"}
                </Typography>
                {request.document && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.open(`${IMAGE_URL}${request.document}`);
                    }}
                  >
                    Скачать документ
                  </Button>
                )}
                <Typography>
                  Создана:{" "}
                  {request.created_at
                    ? formatCustomDate(request.created_at)
                    : "Только что"}
                </Typography>
                {request.accepted_at ? (
                  <Typography>
                    Принята в работу: {formatCustomDate(request.accepted_at)}
                  </Typography>
                ) : (
                  <p></p>
                )}
                {request.completed_at ? (
                  <Typography>
                    Вопрос закрыт: {formatCustomDate(request.completed_at)}
                  </Typography>
                ) : (
                  <p></p>
                )}
              </Stack>
            </Container>
          </Stack>
          <Stack direction="row" p={1}>
            {children}
          </Stack>

          <Divider style={{ padding: "0" }} />
          <RequestChat initialText={initialText} />
        </div>
        <div className="request-chat-add-inf">
          <div>
            {request.image && (
              <Container
                sx={{
                  padding: 1,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  appStore.setImageUrlModal(`${IMAGE_URL}${request.image}`);
                }}
              >
                <img
                  src={`${IMAGE_URL}${request.image}`}
                  alt="Изображение"
                  style={{
                    width: "20rem",
                    maxHeight: 400,
                    objectFit: "contain",
                  }}
                />
              </Container>
            )}
          </div>
          {webSocketStore.request.status === RequestStatusEnum.IN_PROGRESS && (
            <div className="fast_carts">
              <div>
                <div
                  className="fast_panel"
                  onClick={() =>
                    !isDisabled &&
                    fast_function(
                      "Здравствуйте ! Это техническая поддержка сайта hr.alabuga.ru"
                    )
                  }
                  style={{
                    pointerEvents: isDisabled ? "none" : "auto",
                    opacity: isDisabled ? 0.5 : 1,
                  }}
                >
                  Здравствуйте ! Это техническая поддержка сайта hr.alabuga.ru.
                </div>
                <div
                  className="fast_panel"
                  onClick={() =>
                    !isDisabled && fast_function("Какой у вас вопрос ?")
                  }
                  style={{
                    pointerEvents: isDisabled ? "none" : "auto",
                    opacity: isDisabled ? 0.5 : 1,
                  }}
                >
                  Какой у вас вопрос ?
                </div>
              </div>
              <div>
                <div
                  className="fast_panel"
                  onClick={() =>
                    !isDisabled &&
                    fast_function("У вас остались еще какие-нибудь вопросы ?")
                  }
                  style={{
                    pointerEvents: isDisabled ? "none" : "auto",
                    opacity: isDisabled ? 0.5 : 1,
                  }}
                >
                  У вас остались еще какие-нибудь вопросы ?
                </div>
                <div
                  className="fast_panel"
                  onClick={() =>
                    !isDisabled &&
                    fast_function("Рады были помочь ! Всего доброго !")
                  }
                  style={{
                    pointerEvents: isDisabled ? "none" : "auto",
                    opacity: isDisabled ? 0.5 : 1,
                  }}
                >
                  Рады были помочь ! Всего доброго !
                </div>
              </div>
            </div>
          )}
          {webSocketStore.request.status === RequestStatusEnum.IN_PROGRESS && (
            <div className="container_panel">
              <div className="text_panel">
                <p style={{ display: "flex", justifyContent: "center" }}>
                  Заметки
                </p>
                {isEditing ? (
                  <TextField
                    multiline
                    rows={11}
                    value={panel}
                    onChange={(e) => setPanel(e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ height: "100%" }}
                  />
                ) : (
                  <div
                    onClick={() => setIsEditing(true)}
                    className="text_field_panel"
                  >
                    <TextField
                      multiline
                      rows={11}
                      value={panel}
                      onChange={(e) => setPanel(e.target.value)}
                      variant="outlined"
                      fullWidth
                      style={{ height: "100%" }}
                    />
                  </div>
                )}
              </div>
              <div className="dis">
                <Button
                  variant="contained"
                  sx={{ width: "170px" }}
                  onClick={handleSave}
                >
                  Сохранить
                </Button>
              </div>
            </div>
          )}
          <div className="display">
            {webSocketStore.request.status ===
              RequestStatusEnum.IN_PROGRESS && (
              <>
                <Button variant="contained" onClick={() => setClose(true)}>
                  Закончить чат
                </Button>

                <div className="button_hr">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setModalPanel(true)}
                  >
                    Передать заявку
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Paper>
    );
  }
);
