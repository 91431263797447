import { observer } from "mobx-react";
import userStore from "../store/UserStore";
import { useState } from "react";
import { Pagination, Stack, Typography } from "@mui/material";
import { RequestCard } from "../components/request/RequestCard";
import { RequestViewActions } from "../components/request/RequestViewActions";
import { RequestStatusEnum } from "../api/UserApi";
import { WrapperForRequests } from "../components/request/WrapperForRequests";
import "./style.css";

export const MyRequests = observer(() => {
  return (
    <WrapperForRequests
      title="Мои заявки в работе"
      onClickUpdate={() => {
        userStore.updateMyRequests();
      }}
    >
      <Internal />
    </WrapperForRequests>
  );
});

const Internal = observer(() => {
  // ToDo: чуть чуть костыль
  const reqs = userStore.myRequests.filter(
    (req) => req.status === RequestStatusEnum.IN_PROGRESS
  );
  const list = reqs;

  const [page, setPage] = useState(1);
  const perPage = 10;
  const totalPages = Math.ceil(list.length / perPage);

  if (userStore.isLoadingMyRequest) return <Typography>Загрузка...</Typography>;

  if (!userStore.isLoggedIn) return <Typography>Вы не авторизованы</Typography>;

  if (list.length === 0)
    return <Typography>У вас нет заявок в работе</Typography>;

  const filteredList = list.slice((page - 1) * perPage, page * perPage);

  return (
    <>
      <Stack spacing={2} id="list">
        {filteredList
          .sort((a, b) => (a.is_view === b.is_view ? 0 : a.is_view ? 1 : -1))
          .map((request) => {
            return (
              <RequestCard key={request.id} request={request}>
                <RequestViewActions requestId={request.id} />
              </RequestCard>
            );
          })}
      </Stack>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(event, value) => setPage(value)}
      />
    </>
  );
});
