import { observer } from "mobx-react";
import userStore from "../store/UserStore";
import { useState } from "react";
import { Pagination, Stack, Typography, Button } from "@mui/material";
import { RequestCard } from "../components/request/RequestCard";
import { RequestViewActions } from "../components/request/RequestViewActions";
import { RequestStatusEnum } from "../api/UserApi";
import { WrapperForRequests } from "../components/request/WrapperForRequests";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "../constants";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./style.css";

export const ArchiveMyRequests = observer(() => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleDownload = async () => {
    try {
      const csrfToken = Cookies.get("csrftoken");
      const headers = {};
      if (csrfToken) {
        headers["X-CSRFToken"] = csrfToken;
        headers["Content-Type"] = "application/json";
      }
      const response = await axios.get(
        `${BASE_URL}/user/request/statistics?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: headers,
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      let downloadCounter = localStorage.getItem("downloadCounter");
      if (!downloadCounter) {
        downloadCounter = 1;
      } else {
        downloadCounter = parseInt(downloadCounter, 10);
      }
      link.setAttribute(
        "download",
        `Статистика заявок за месяц №${downloadCounter}.xls`
      );
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      localStorage.setItem("downloadCounter", downloadCounter + 1);
    } catch (error) {
      console.error("Ошибка при скачивании файла", error);
    }
  };

  return (
    <div className="zak">
      <WrapperForRequests
        title="Архивные заявки"
        onClickUpdate={() => {
          userStore.updateMyRequests();
        }}
      >
        <div className="cont">
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Выгрузка за месяц
          </Button>

          <TextField
            sx={{ width: "70%" }}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            label="Поиск пользователя"
          />
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Выберите диапазон дат</DialogTitle>
          <DialogContent>
            <TextField
              label="Дата начала"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Дата окончания"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Отмена
            </Button>
            <Button
              onClick={handleDownload}
              color="primary"
              variant="contained"
            >
              Выгрузка
            </Button>
          </DialogActions>
        </Dialog>

        <Internal filter={value} />
      </WrapperForRequests>
    </div>
  );
});

const Internal = observer(({ filter }) => {
  const reqs = userStore.myRequests.filter(
    (req) =>
      req.status === RequestStatusEnum.DONE ||
      req.status === RequestStatusEnum.DECLINED
  );

  const filteredReqs = reqs.filter((req) => {
    if (!req.user || !req.user.t_first_name || !req.user.t_last_name)
      return false;
    const fullName =
      `${req.user.t_first_name} ${req.user.t_last_name}`.toLowerCase();
    const reverseFullName =
      `${req.user.t_last_name} ${req.user.t_first_name}`.toLowerCase();
    return (
      fullName.includes(filter.toLowerCase()) ||
      reverseFullName.includes(filter.toLowerCase())
    );
  });

  const [page, setPage] = useState(1);
  const perPage = 6;
  const totalPages = Math.ceil(filteredReqs.length / perPage);

  const paginatedReqs = filteredReqs.slice(
    (page - 1) * perPage,
    page * perPage
  );

  return (
    <>
      <Typography>
        {userStore.isLoadingMyRequest
          ? "Загрузка..."
          : !userStore.isLoggedIn
          ? "Вы не авторизованы"
          : filteredReqs.length === 0
          ? "Нет заявок, соответствующих фильтру"
          : ""}
      </Typography>
      <Stack spacing={2} id="list">
        {paginatedReqs.map((request) => (
          <RequestCard key={request.id} request={request} detail>
            <RequestViewActions requestId={request.id} />
          </RequestCard>
        ))}
      </Stack>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(_, value) => setPage(value)}
      />
    </>
  );
});
