import { Button, Container, Paper, Stack, TextField } from "@mui/material";
import { observer } from "mobx-react";
import webSocketStore from "../../store/WebSocketStore";
import { useEffect, useRef, useState } from "react";
import { RequestMessage } from "./RequestMessage";
import { RequestStatusEnum } from "../../api/UserApi";
import "./RequestMessage.css";
import input from "./img/inputimage.png";

import Cookies from "js-cookie";
import UserApi from "../../api/UserApi";
import * as React from "react";

export const RequestChat = observer((initialText) => {
  const stackMessagesRef = useRef();
  const [messageInput, setMessageInput] = useState("");
  const messages = webSocketStore.request.messages;
  const [file, setFile] = useState(null);
  const [messageSent, setMessageSent] = useState(false);

  const sortedMessages = messages.slice().sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  });

  const sendMessage = () => {
    if (!messageInput && !file) return;

    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const fileData = event.target.result;

        if (file.type.startsWith("image/")) {
          webSocketStore.binarySocketService.sendPhotoToUser(fileData);
        } else if (file.type.startsWith("video/")) {
          webSocketStore.binarySocketService.sendVideoToUser(fileData);
        }
        if (messageInput) {
          webSocketStore.binarySocketService.sendMessageToUser(messageInput);
          setMessageInput("");
        }
      };
      reader.readAsDataURL(file);
      setFile(null);
    } else {
      webSocketStore.binarySocketService.sendMessageToUser(messageInput);
      setMessageInput("");
    }
  };

  const instantScrollDown = () => {
    stackMessagesRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    instantScrollDown();
    const messageExists = messages.some(
      (mess) =>
        mess.text ===
        "Мы приняли вашу заявку, ниже вы можете написать сообщения:"
    );

    if (!messageExists && !messageSent) {
      webSocketStore.binarySocketService.sendMessageToUser(
        "Мы приняли вашу заявку, ниже вы можете написать сообщения:"
      );
      setMessageSent(true);
    }
    const csrfToken = Cookies.get("csrftoken");
    const headers = {};
    if (csrfToken) {
      headers["X-CSRFToken"] = csrfToken;
    }

    webSocketStore.stackMessagesRef = stackMessagesRef;
  }, [messages, messageSent]);

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        setFile(file);
      }
    }
  };

  return (
    <Container>
      <Stack sx={{ padding: 2 }}>
        <Paper
          elevation={0}
          sx={{
            height: "70vh",
            maxWidth: "40rem",
            overflowY: "scroll",
            background: "rgb(2,0,36)",
            background:
              "linear-gradient(125deg, rgb(1, 0, 27) 0%, rgb(5, 5, 63) 80%, rgb(6, 6, 81) 100%, rgba(0, 19, 66, 0.8) 100%)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.38)",
          }}
        >
          <Stack
            spacing={3}
            p={1}
            justifyContent="flex-end"
            ref={stackMessagesRef}
          >
            {sortedMessages.map((message) => (
              <RequestMessage message={message} key={message.id} />
            ))}
          </Stack>
        </Paper>

        {webSocketStore.request.status === RequestStatusEnum.IN_PROGRESS && (
          <Stack
            className="column"
            direction="row"
            style={{ height: "5rem", marginTop: "1rem", gap: "1rem" }}
          >
            <div className="input-wrapper">
              <input
                className="input"
                type="file"
                onChange={(event) => setFile(event.target.files[0])}
              />
              <img
                className="img"
                src={file ? URL.createObjectURL(file) : input}
                alt="Upload"
              />
            </div>
            <TextField
              sx={{ width: "90%", height: "100%" }}
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              label="Введите сообщение"
              multiline
              minRows={1}
              onPaste={handlePaste}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
            <Button
              variant="contained"
              sx={{ width: "150px", height: "5rem" }}
              onClick={sendMessage}
            >
              Отправить
            </Button>
          </Stack>
        )}
      </Stack>
    </Container>
  );
});
