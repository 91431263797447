import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { MyRequests } from "../pages/MyRequests";
import { NotFound } from "../pages/NotFound";
import { RequestView } from "../pages/RequestView";
import webSocketStore from "../store/WebSocketStore";
import { ArchiveMyRequests } from "../pages/ArchiveMyRequests";
import { FAQ } from "../pages/FAQ";

export const RoutesEnum = Object.freeze({
  HOME: "home",
  LOGIN: "login",
  MY_REQUESTS: "myRequests",
  ARCHIVE_MY_REQUESTS: "archiveMyRequests",
  REQUEST_VIEW: "requestView",
  FAQ: "faq",
  NOT_FOUND: "notFound",
});

export const PATHS = {
  [RoutesEnum.HOME]: "/",
  [RoutesEnum.LOGIN]: "/login",
  [RoutesEnum.MY_REQUESTS]: "/my-requests",
  [RoutesEnum.ARCHIVE_MY_REQUESTS]: "/archive-my-requests",
  [RoutesEnum.REQUEST_VIEW]: "/request/:id",
  [RoutesEnum.FAQ]: "/faq",
  [RoutesEnum.NOT_FOUND]: "/not-found",
};

export const Routes = [
  { name: RoutesEnum.HOME, pattern: PATHS.home },
  { name: RoutesEnum.LOGIN, pattern: PATHS.login },
  { name: RoutesEnum.MY_REQUESTS, pattern: PATHS.myRequests },
  { name: RoutesEnum.FAQ, pattern: PATHS.faq },
  {
    name: RoutesEnum.REQUEST_VIEW,
    pattern: PATHS.requestView,
    onExit: () => {
      webSocketStore.closeConnection();
    },
  },
  {
    name: RoutesEnum.ARCHIVE_MY_REQUESTS,
    pattern: PATHS.archiveMyRequests,
    onExit: () => {
      webSocketStore.closeConnection();
    },
  },

  {
    name: RoutesEnum.NOT_FOUND,
    pattern: "/not-found",
  },
];

export const viewMap = {
  home: <Home />,
  login: <Login />,
  myRequests: <MyRequests />,
  archiveMyRequests: <ArchiveMyRequests />,
  requestView: <RequestView />,
  notFound: <NotFound />,
  faq: <FAQ />,
};
