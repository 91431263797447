import Avatar from "@mui/material/Avatar";
import { observer } from "mobx-react-lite";
import userStore from "../../store/UserStore";
import React, { useState } from "react";
import { Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useRouterStore } from "mobx-state-router";

import { RoutesEnum } from "../../router";

import { Logout, Settings } from "@mui/icons-material";

export const DrawerSettingMenu = observer(({ open, setAnchorEl, anchorEl }) => {
  const routerStore = useRouterStore();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogin = async () => {
    handleClose();
    await routerStore.goTo(RoutesEnum.LOGIN);
  };

  const handleLogout = async () => {
    handleClose();
    await userStore.logout();
    await routerStore.goTo(RoutesEnum.LOGIN);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      hideBackdrop
      style={{ marginLeft: "2rem" }}
      PaperProps={{
        sx: {
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
          },
          minWidth: 220,
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem style={{ pointerEvents: "none" }}>
        <Avatar />
        {userStore?.me?.username || "Profile"}
      </MenuItem>
      <Divider />
      {userStore.isLoggedIn ? (
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Выйти
        </MenuItem>
      ) : (
        <MenuItem onClick={handleLogin}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Войти
        </MenuItem>
      )}
    </Menu>
  );
});
