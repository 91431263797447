import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { CustomDrawer } from "./Drawer";

export default function SideBar() {
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
            backgroundColor: "rgba(2,0,36,1) ",
          },
        }}
        open
      >
        <CustomDrawer />
      </Drawer>
    </Box>
  );
}
