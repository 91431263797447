import { Container, Paper, Stack, Typography } from "@mui/material";
import { RoleUserEnum, USER_ROLES } from "../../api/UserApi";
import { textFormatTgUser } from "../../utils/tgUser";
import { BASE_MEDIA } from "../../constants";
import "./RequestMessage.css";
import { formatCustomDateHour } from "../../utils/date";
import { Avatar } from "@mui/material";
import { observer } from "mobx-react-lite";

function formatUserFrom(message) {
  const user = message.user;
  const isAdmin = USER_ROLES.includes(user.role);
  const userData = textFormatTgUser(user);
  console.log(isAdmin);
  return {
    isAdmin: isAdmin,
    userData: userData,
  };
}

export const RequestMessage = observer(({ message }) => {
  return (
    <Paper
      key={message.id}
      style={{ background: "#0b0871cf", border: "1px solid #0b0795db" }}
    >
      <Stack direction="row" spacing={1}>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack className="width" p={2} spacing={1}>
            {message.image ? (
              <>
                <img className="img" src={BASE_MEDIA + message.image} alt="" />
                <Typography style={{ whiteSpace: "pre-wrap" }}>
                  {message.text}
                </Typography>
                <p className="p">{formatCustomDateHour(message.created_at)}</p>
              </>
            ) : message.video ? (
              <>
                <video className="video" controls>
                  <source src={BASE_MEDIA + message.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <Typography style={{ whiteSpace: "pre-wrap" }}>
                  {message.text}
                </Typography>
                <p className="p">{formatCustomDateHour(message.created_at)}</p>
              </>
            ) : (
              <div
                style={{ position: "relative" }}
                className={` ${
                  formatUserFrom(message).isAdmin
                    ? "admin-message"
                    : "user-message"
                }`}
              >
                <Typography style={{ whiteSpace: "pre-wrap" }}>
                  {message.text}
                </Typography>
                <p
                  className={`p ${
                    formatUserFrom(message).isAdmin
                      ? "admin-message-description"
                      : "user-message-description"
                  }`}
                >
                  {formatUserFrom(message).userData}
                  {formatCustomDateHour(message.created_at)}
                </p>
              </div>
            )}
          </Stack>
        </Container>
      </Stack>
    </Paper>
  );
});
