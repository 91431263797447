import { Stack } from "@mui/material";
import { observer } from "mobx-react";

export const WrapperForRequests = observer(({ children }) => {
  return (
    <Stack style={{ width: "100%" }}>
      <Stack direction="row" spacing={1}></Stack>
      {children}
    </Stack>
  );
});
