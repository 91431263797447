import { WS_URL } from "../constants";

export const BinarySocketMsgType = {
  SYNCHRONIZE: "synchronize",
  NEW_MESSAGE: "new_message",
  END_CHAT: "end_chat",
  ERROR: "error",
};

export const BinarySocketEvent = {
  SEND_MESSAGE: "send_message",
  END_CHAT: "end_chat",
  SEND_PHOTO: "photo_message",
  SEND_VIDEO: "video_message",
};

const ONE_TIME_HANDLERS_TIMEOUT = 1000;

const RECONNECT_DELAY = 5000;

export class BinarySocketService {
  isConnect = false;
  socket = null;
  requestId = null;
  mapHandlersMessage;
  reconnectAttempts = 9999;
  reconnectTimeout = null;

  constructor() {
    this.mapHandlersMessage = new Map();
    this.oneTimeHandlers = new Map();
  }

  openConnection({ requestId, callback, token }) {
    this.socket = new WebSocket(
      `${WS_URL}/ws/user/request/${requestId}?token=${token}`
    );
    console.log("Connecting...");

    this.socket.onopen = () => {
      console.log("WebSocket connected");
      this.isConnect = true;
      this.requestId = requestId;
      this.socket.onmessage = this.onMessageHandler;

      if (callback) callback();
    };

    this.socket.onclose = () => {
      console.log("WebSocket disconnected");
      this.isConnect = false;
      this.socket = null;
      this.handleReconnect(requestId, token);
    };

    this.socket.onerror = (error) => {
      console.log("WebSocket error:", error);
      this.isConnect = false;
      this.socket.close(); // Закрытие соединения при ошибке
    };
  }

  closeConnection(callback) {
    if (!this.socket) return;

    this.socket.close();
    console.log("Closing WebSocket...");

    this.socket.onclose = () => {
      this.isConnect = false;
      this.socket = null;
      if (callback) callback();
    };
  }

  handleReconnect(requestId, token) {
    if (this.reconnectAttempts > 0) {
      console.log(
        `Attempting to reconnect, ${this.reconnectAttempts} attempts left...`
      );

      // Создаем модальное окно динамически
      const modalHtml = `
                <div id="reconnect-modal" class="modal" style="display: none; position: fixed; z-index: 1000; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5);">
                    <div class="modal-content" style="position: absolute; top: 15%; left: 50%; transform: translate(-50%, -50%); background-color: #777777; padding: 20px; border-radius: 5px; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
                        <span id="close-modal" class="close" style="position: absolute; top: 10px; right: 15px; font-size: 22px; cursor: pointer;">&times;</span>
                        <p style="color: black;" id="modal-message">Проблемы с соединением. Переподключение сокет...</p>
                    </div>
                </div>
            `;

      // Вставляем модальное окно в конец body
      document.body.insertAdjacentHTML("beforeend", modalHtml);

      // Получаем элементы модального окна
      const modal = document.getElementById("reconnect-modal");
      const closeModalButton = document.getElementById("close-modal");

      // Показ модального окна
      modal.style.display = "block";

      // Автоматическое закрытие через 3 секунды
      setTimeout(() => {
        modal.style.display = "none";
        // Удаляем модальное окно из DOM после закрытия
        modal.remove();
      }, 5000);

      // Закрытие модального окна по нажатию на крестик
      closeModalButton.onclick = () => {
        modal.style.display = "none";
        modal.remove();
      };

      this.reconnectTimeout = setTimeout(() => {
        this.reconnectAttempts--;
        this.openConnection({ requestId, token });
      }, RECONNECT_DELAY);
    } else {
      console.log("Max reconnect attempts reached. Giving up.");
    }
  }

  sendMessage(event) {
    if (this.socket && this.isConnect) {
      this.socket.send(JSON.stringify(event));
    } else {
      console.log("WebSocket is not connected. Message not sent.");
    }
  }

  onMessageHandler = (msg) => {
    try {
      const data = JSON.parse(msg.data);
      const msgType = data.type;

      this.mapHandlersMessage
        .get(msgType)
        ?.forEach((handler) => handler(data.data));

      this.oneTimeHandlers
        .get(msgType)
        ?.forEach(([idHandler, handler]) => handler(data.data));
      this.oneTimeHandlers.delete(msgType);
    } catch (err) {
      console.log("Parse message error: ", err);
    }
  };

  addMessageHandler(msgType, handler) {
    const handlers = this.mapHandlersMessage.get(msgType) || [];
    handlers.push(handler);
    this.mapHandlersMessage.set(msgType, handlers);
  }

  addOneTimeMessageHandler(msgType, handler) {
    const idHandler = Math.random();
    const handlers = this.oneTimeHandlers.get(msgType) || [];
    handlers.push([idHandler, handler]);

    this.oneTimeHandlers.set(msgType, handlers);

    setTimeout(() => {
      const currentHandlers = this.oneTimeHandlers.get(msgType) || [];
      this.oneTimeHandlers.set(
        msgType,
        currentHandlers.filter((h) => h[0] !== idHandler)
      );
    }, ONE_TIME_HANDLERS_TIMEOUT);
  }

  // Methods to send specific messages
  sendMessageToUser(message) {
    this.sendMessage({
      type: BinarySocketEvent.SEND_MESSAGE,
      data: { text: message },
    });
  }

  sendPhotoToUser(image) {
    this.sendMessage({
      type: BinarySocketEvent.SEND_PHOTO,
      data: { image },
    });
  }

  sendVideoToUser(video) {
    this.sendMessage({
      type: BinarySocketEvent.SEND_VIDEO,
      data: { video },
    });
  }

  sendEndChat() {
    this.sendMessage({
      type: BinarySocketEvent.END_CHAT,
    });
  }
}
