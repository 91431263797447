import {
  Box,
  Button,
  Container,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { useState } from "react";
import userStore from "../store/UserStore";
import { useRouterStore } from "mobx-state-router";
import { RoutesEnum } from "../router";
import { useSnackbar } from "notistack";

export const Login = observer(() => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const routerStore = useRouterStore();

  const handleLogin = async () => {
    const response = await userStore.login({
      username: login,
      password,
    });
    if (response == true) {
      await routerStore.goTo(RoutesEnum.HOME);
    }
  };

  return (
    <Container>
      <Paper>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleLogin();
          }}
        >
          <Box p={2}>
            <Typography variant="h4" align="center">
              Вход
            </Typography>
            <TextField
              label="Логин"
              id="username"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Пароль"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" type="submit">
              Войти
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
});
