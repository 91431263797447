export function formatCustomDate(date) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(date).toLocaleDateString("ru", options);
}

export function formatCustomDateHour(date) {
  const options = { hour: "numeric", minute: "numeric" };
  return new Date(date).toLocaleDateString("ru", options);
}
