import { Button, Container } from "@mui/material";
import { useRouterStore } from "mobx-state-router";
import { RoutesEnum } from "../../router";
import { observer } from "mobx-react";

export const RequestViewActions = observer(({ requestId }) => {
  const routerStore = useRouterStore();

  const handleClick = () => {
    routerStore.goTo(RoutesEnum.REQUEST_VIEW, {
      params: { id: requestId.toString() },
    });
  };

  return (
    <Container>
      <Button variant="contained" color="primary" onClick={handleClick}>
        Посмотреть
      </Button>
    </Container>
  );
});
